import React from 'react'

const Pig: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4442_16378)">
        <path
          d="M27.125 24.5H37.625"
          stroke="#00CC2C"
          strokeWidth="1.75"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M39.375 19.2499H21.875V19.2499C21.8707 19.2499 21.8666 19.2475 21.8644 19.2438C19.0037 14.3388 14.7463 13.6887 12.3367 13.7688C11.3305 13.8023 10.7684 14.8052 11.0868 15.7602L12.7822 20.8466C13.0242 21.5724 12.7594 22.3637 12.1793 22.8626C10.402 24.3909 8.9877 26.3276 8.09023 28.5325C7.80053 29.2442 7.13184 29.7499 6.36343 29.7499H3.5C2.5335 29.7499 1.75 30.5334 1.75 31.4999V40.2499C1.75 41.2164 2.5335 41.9999 3.5 41.9999H8.30338C8.90625 41.9999 9.46053 42.3149 9.81293 42.804C11.5954 45.2781 14.1165 47.1811 17.054 48.1904C17.8179 48.4529 18.375 49.1453 18.375 49.9531V52.4999C18.375 53.4664 19.1585 54.2499 20.125 54.2499H25.375C26.3415 54.2499 27.125 53.4664 27.125 52.4999V50.7499C27.125 49.7834 27.9085 48.9999 28.875 48.9999H34.125C35.0915 48.9999 35.875 49.7834 35.875 50.7499V52.4999C35.875 53.4664 36.6585 54.2499 37.625 54.2499H42.875C43.8415 54.2499 44.625 53.4664 44.625 52.4999V49.2383C44.625 48.5104 45.0803 47.8673 45.738 47.5555C50.7672 45.1713 54.25 40.061 54.25 34.1249C54.25 25.9096 47.5904 19.2499 39.375 19.2499Z"
          fill="#8CF9A4"
          stroke="#00CC2C"
          strokeWidth="1.75"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.625 33.25C18.0747 33.25 19.25 32.0747 19.25 30.625C19.25 29.1753 18.0747 28 16.625 28C15.1753 28 14 29.1753 14 30.625C14 32.0747 15.1753 33.25 16.625 33.25Z"
          fill="white"
          stroke="#00CC2C"
          strokeWidth="1.75"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M32.375 12.25C35.2745 12.25 37.625 9.8995 37.625 7C37.625 4.10051 35.2745 1.75 32.375 1.75C29.4755 1.75 27.125 4.10051 27.125 7C27.125 9.8995 29.4755 12.25 32.375 12.25Z"
          fill="white"
          stroke="#00CC2C"
          strokeWidth="1.75"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_4442_16378">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Pig
